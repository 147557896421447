import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlocksRenderer from "../components/blocks-renderer"


const ArtistPage = ({ data }) => {

  const artist = data.strapiArtist
  const home = data.strapiHome
  const seo = {
    metaTitle: artist.name,
    metaDescription: artist.bioText,
    shareImage: artist.bioPhoto,
}
  return (
    <Layout>
      <Seo seo={seo} />
      <div class="container">
        <div class="flex lg:flex-nowrap flex-wrap justify-center gap-4">
          <div class="text-center mt-6 shrink-0 lg:w-1/3">
            <GatsbyImage
              image={getImage(artist?.bioPhoto?.localFile)}
              alt={artist?.bioPhoto?.alternativeText}
              className="mt-6"
            />
            <h1 className="text-4xl font-bold mt-4 font-extralight text-tpBlue">{artist.name}</h1>
            <h3 className="text-2xl font-bold mt-1 text-yellow-500">Artist</h3>
            <div class="text-black-500 mt-8">
              <div class="font-light text-tpBlue text-xl border-b-2 border-t-2 border-tpBlue border-dotted p-1 text-center">
                INFO
              </div>
              <div class="font-bold text-xl mt-10">RESIDENCY SEASON:</div>
              <div class="text-xl">
                <a href={`https://thermiaproject.com/artist-showcase#${artist.season.year}`}>
                  <span class="border-black border-dotted border-b-2 cursor-pointer">{artist.season.year}</span></a>
              </div>
              <div class="font-bold text-xl mt-2">ARTIST’S WEBSITE:</div>
              <div class="text-xl">
                <span className={!artist.siteUrl && 'hidden'}><a href={artist.siteUrl}>Website</a></span>
                <span> </span>
                <span className={!artist.instaUrl && 'hidden'}><a href={artist.instaUrl}>Insta</a></span>
                <span className={artist.instaUrl || artist.siteUrl ? 'hidden' : ''}><a href={artist.instaUrl}>-</a></span>
              </div>
              <div class="mt-8 font-mono text-yellow-500 text-centered">
                <div class="lg:w-full w-96 m-auto">
                  Please note all artist-in-residence biographies are accurate at the time of their residency. For up-to-date bios please visit the artist’s website.
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="font-light text-tpBlue text-4xl border-b-2 border-t-2 border-tpBlue p-2 text-center mt-12 ">
              BIO
            </div>
            <p className="mt-4 text-2xl text-tpBlackish tracking-wider leading-10">{artist.bioText}</p>
          </div>
        </div>
      </div>
      <main class="mt-16">
        <div class="font-light text-tpBlue text-4xl border-b-2 border-t-2 border-tpBlue p-2 text-center mt-20 mb-16">
          PAST WORK
        </div>
        <BlocksRenderer blocks={artist.blocks || []} />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiHome {
        thermiaLogo {
            alternativeText
            localFile {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    }
    strapiArtist(slug: { eq: $slug }) {
      id
      name
      bioText
      blocks {
        ...Blocks
      }
      season{
        year
      }
      instaUrl,
      siteUrl,
      bioPhoto {
        alternativeText
        localFile {
          url
          localURL
          childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                width: 400
                formats: [WEBP]
              )
            }          
        }
      }
    }
  }
`

export default ArtistPage